import StatisticSalesFunnelProgress from './StatisticSalesFunnelProgress'
import Card from '../../core/components/Card'
import { useSalesFunnelStats } from '../hooks/stats'

export type Props = {
    startDate: string
    endDate: string
}

export default function StatisticsSalesFunnel({ startDate, endDate }: Props) {
    const salesFunnel = useSalesFunnelStats(startDate, endDate)

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Воронка продаж
            </div>

            <StatisticSalesFunnelProgress
                salesFunnel={salesFunnel.data || []}
                loading={salesFunnel.isLoading}/>
        </Card>
    )
}
