import Card from '../../core/components/Card'
import StatisticsAcademyStudentAgeLine from './StatisticsAcademyStudentYearLine'
import { useStudentAgeStats } from '../hooks/stats'



export default function StatisticsAcademyStudentYears() {
    const students = useStudentAgeStats()

    return (
        <Card>
            <div className="title is-5 has-text-centered">
                Статистика Студентов
            </div>

            <StatisticsAcademyStudentAgeLine
                data={students.data || []}
                loading={students.isLoading} />
        </Card>
    )
}
