import { StudentYearStatisticsType } from '../types'
import Progress from '../../core/components/Progress'
import Loader from '../../core/components/Loader'
import maxValue from '../../core/utils/array'
import { colors } from '../utils/colors'

export type Props = {
    data: StudentYearStatisticsType[]
    loading: boolean
}

export default function StatisticsAcademyStudentAgeLine({ data, loading }: Props) {

    const maxCount = maxValue(data.map(item => item.count))

    return (
        <div>
            {!loading ? data.map((item, index) => (
                <div key={item.title} className="columns">
                    <span className="column">{item.title} лет  ({item.count}): </span>
                    <span className="column is-four-fifths">
                        <Progress
                            value={item.count}
                            text={item.count}
                            background={colors[index]}
                            max={maxCount}/>
                    </span>
                </div>
            )) : <Loader large/>}
        </div>
    )
}
